import clsx from 'clsx';
import type { FC, JSXElementConstructor, ReactNode, SVGAttributes } from 'react';
import { ButtonBase, type ButtonBaseProps } from './ButtonBase';

export interface IconButtonProps extends Omit<ButtonBaseProps, 'prefix' | 'suffix'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: JSXElementConstructor<any>;
  iconProps?: SVGAttributes<SVGElement>;
  label?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
}

export const IconButton: FC<IconButtonProps> = ({
  icon: Icon,
  prefix,
  suffix,
  label,
  className,
  iconProps,
  ...props
}) => (
  <ButtonBase
    className={clsx(
      'icon-button inline-flex h-10 flex-shrink-0 flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded-full border-none bg-gray-100 text-gray-700 placeholder:bg-transparent hover:bg-gray-200 hover:text-gray-900 focus:bg-gray-300/70 focus:text-gray-900 focus:ring-transparent',
      {
        'px-3': prefix || suffix,
        'w-10': !prefix && !suffix,
      },
      className,
    )}
    {...props}
  >
    {prefix}

    {label && <span className="sr-only">{label}</span>}

    <Icon {...iconProps} className={clsx(iconProps?.className, 'h-6 w-6 text-current')} />

    {suffix}
  </ButtonBase>
);
